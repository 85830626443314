<!--
Copyright © MindMaze Holding SA 2020.  All Rights Reserved.
Unauthorized copying of this file, via any medium is strictly prohibited.
CONFIDENTIAL: This software is proprietary and confidential. It cannot be copied
and/or distributed without the express prior written permission of MindMaze Holding SA.
-->

<template>
    <div class="container">
        <b-row>
            <b-col>
                <b-card header="Firmware Updates Manager">
                    <b-row>
                        <b-col>
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th>Firmware Update</th>
                                        <th>Status</th>
                                        <th>Mandatory</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="update in firmwareUpdates"
                                        :key="update.id"
                                    >
                                        <td>
                                            <b-list-group class="text-right">
                                                <b-list-group-item
                                                    class="user-role"
                                                    key="0"
                                                >
                                                    Controller v{{
                                                        update.versionUnit0
                                                    }}
                                                </b-list-group-item>
                                                <b-list-group-item
                                                    class="user-role"
                                                    key="1"
                                                    >Communication v{{
                                                        update.versionUnit1
                                                    }}
                                                </b-list-group-item>
                                                <b-list-group-item
                                                    class="user-role"
                                                    key="2"
                                                >
                                                    Screen v{{
                                                        update.versionUnit2
                                                    }}
                                                </b-list-group-item>
                                                <b-list-group-item
                                                    class="user-role"
                                                    key="3"
                                                >
                                                    Stimulation v{{
                                                        update.versionUnit3
                                                    }}
                                                </b-list-group-item>
                                            </b-list-group>
                                        </td>
                                        <td>{{ update.deployment }}</td>
                                        <td>
                                            {{
                                                update.isMandatoryUpdate
                                                    ? "Yes"
                                                    : "No"
                                            }}
                                        </td>
                                        <td class="text-right">
                                            <b-row>
                                                <b-button
                                                    @click.prevent="
                                                        getFirmwareFile(
                                                            update.firmwareFileId
                                                        )
                                                    "
                                                    variant="outline-primary"
                                                    size="sm"
                                                    class="my-1"
                                                >
                                                    Download
                                                </b-button>
                                                <b-button
                                                    v-if="isIntentoSoftware"
                                                    variant="outline-primary"
                                                    size="sm"
                                                    class="m-1"
                                                    @click.prevent="
                                                        populateEditFirmwareUpdateForm(
                                                            update.id
                                                        )
                                                    "
                                                >
                                                    <b-icon-pencil />
                                                </b-button>
                                            </b-row>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col>
                            <b-toast
                                id="toast-upload-failed"
                                title="Upload Error"
                                static
                                auto-hide-delay="1000"
                            >
                                Firmware upload failed.
                            </b-toast>

                            <b-button
                                v-if="isIntentoSoftware"
                                @click.prevent="openNewFirmwareUpdateForm"
                                size="sm"
                                class="m-1"
                                variant="primary"
                            >
                                <b-icon-plus font-scale="1.3"></b-icon-plus>
                                New Firmware Update
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>

            <b-modal
                id="modal-upload-firmware-update"
                @ok="uploadFirmwareUpdate"
                ok-title="Upload"
                title="Upload Firmware Update"
            >
                <form @submit.prevent="uploadFirmwareUpdate">
                    <b-form-file
                        v-model="firmwareFile"
                        :state="Boolean(firmwareFile)"
                        placeholder="Upload file containing firmware update..."
                        drop-placeholder="Drop file here..."
                    ></b-form-file>
                </form>
            </b-modal>

            <b-modal
                id="modal-edit-firmware-update-metadata"
                @ok="saveFirmwareUpdateMetadata"
                ok-title="Save"
                title="Edit Firmware Update Metadata"
            >
                <form @submit.prevent="saveFirmwareUpdateMetadata">
                    <b-form-group label="Deployment Status">
                        <b-form-radio
                            v-model="model.deployment"
                            name="deployment-status"
                            value="Production"
                            >Production</b-form-radio
                        >
                        <b-form-radio
                            v-model="model.deployment"
                            name="deployment-status"
                            value="Debug"
                            >Debug</b-form-radio
                        >
                        <b-form-radio
                            v-model="model.deployment"
                            name="deployment-status"
                            value="Pending"
                            >Pending</b-form-radio
                        >
                        <b-form-radio
                            v-model="model.deployment"
                            name="deployment-status"
                            value="Outdated"
                            >Outdated</b-form-radio
                        >
                        <b-form-radio
                            v-model="model.deployment"
                            name="deployment-status"
                            value="SingleDevice"
                            >Single-device</b-form-radio
                        >
                    </b-form-group>
                    <b-form-group
                        label="Tablet Serial Number"
                        v-if="model.deployment == 'SingleDevice'"
                    >
                        <b-form-input
                            type="text"
                            v-model="model.tabletDeploymentSerialNumber"
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group label="Mandatory?">
                        <b-form-radio
                            v-model="model.isMandatoryUpdate"
                            name="isMandatoryUpdate"
                            value="True"
                            >Yes</b-form-radio
                        >
                        <b-form-radio
                            v-model="model.isMandatoryUpdate"
                            name="isMandatoryUpdate"
                            value="False"
                            >No</b-form-radio
                        >
                    </b-form-group>
                    <b-form-group label="Release Notes (EN)">
                        <b-form-textarea
                            v-model="model.release_notes_en"
                            id="release_notes_en"
                            placeholder=""
                            rows="3"
                            max-rows="6"
                        ></b-form-textarea>
                    </b-form-group>
                    <b-form-group label="Release Notes (FR)">
                        <b-form-textarea
                            v-model="model.release_notes_fr"
                            id="release_notes_fr"
                            placeholder=""
                            rows="3"
                            max-rows="6"
                        ></b-form-textarea>
                    </b-form-group>
                    <b-form-group label="Release Notes (DE)">
                        <b-form-textarea
                            v-model="model.release_notes_de"
                            id="release_notes_de"
                            placeholder=""
                            rows="3"
                            max-rows="6"
                        ></b-form-textarea>
                    </b-form-group>
                    <b-form-group label="Release Notes (IT)">
                        <b-form-textarea
                            v-model="model.release_notes_it"
                            id="release_notes_it"
                            placeholder=""
                            rows="3"
                            max-rows="6"
                        ></b-form-textarea>
                    </b-form-group>
                    <b-form-group label="Release Notes (ES)">
                        <b-form-textarea
                            v-model="model.release_notes_es"
                            id="release_notes_es"
                            placeholder=""
                            rows="3"
                            max-rows="6"
                        ></b-form-textarea>
                    </b-form-group>
                </form>
                <div class="form-group">
                    <div v-if="errorMessage" class="alert alert-danger" role="alert">
                        {{ errorMessage }}
                    </div>
                </div>
            </b-modal>
        </b-row>

        <b-row class="mt-5">
            <b-col>
                <b-card header="Tablet Updates Manager">
                    <b-row>
                        <b-col>
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th>App Version</th>
                                        <th>Mandatory</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="update in tabletUpdates"
                                        :key="update.id"
                                    >
                                        <td>
                                            {{ update.version }}
                                        </td>
                                        <td>
                                            {{
                                                update.isMandatoryUpdate
                                                    ? "Yes"
                                                    : "No"
                                            }}
                                        </td>
                                        <td>
                                            <b-button
                                                v-if="isIntentoSoftware"
                                                variant="outline-primary"
                                                size="sm"
                                                class="m-1"
                                                @click.prevent="
                                                    populateEditTabletUpdateForm(
                                                        update.id
                                                    )
                                                "
                                            >
                                                <b-icon-pencil />
                                            </b-button>
                                            <b-button
                                                v-if="isIntentoSoftware"
                                                variant="outline-primary"
                                                size="sm"
                                                class="m-1"
                                                @click.prevent="
                                                    populateIfuForm(update.id)
                                                "
                                            >
                                                IFU
                                            </b-button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col>
                            <b-button
                                v-if="isIntentoSoftware"
                                @click.prevent="openTabletAppUpdateForm"
                                size="sm"
                                class="m-1"
                                variant="primary"
                            >
                                <b-icon-plus font-scale="1.3"></b-icon-plus>New
                                Tablet Update
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>

            <b-modal
                id="modal-edit-app-update-metadata"
                @ok="saveAppUpdateMetadata"
                ok-title="Save"
                :title="
                    modelApp.id
                        ? 'Edit App Update Metadata'
                        : 'Create App Update Metadata'
                "
            >
                <form @submit.prevent="saveAppUpdateMetadata">
                    <b-form-group label="Software Version">
                        <b-form-input
                            type="text"
                            v-model="modelApp.version"
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group label="Mandatory ?">
                        <b-form-radio
                            v-model="modelApp.isMandatoryUpdate"
                            name="isMandatoryUpdate"
                            value="True"
                        >
                            Yes
                        </b-form-radio>
                        <b-form-radio
                            v-model="modelApp.isMandatoryUpdate"
                            name="isMandatoryUpdate"
                            value="False"
                        >
                            No
                        </b-form-radio>
                    </b-form-group>
                </form>
                <div class="form-group">
                    <div v-if="errorMessage" class="alert alert-danger" role="alert">
                        {{ errorMessage }}
                    </div>
                </div>
            </b-modal>

            <b-modal
                id="modal-edit-ifu-update"
                title="Instruction For Use Information"
                size="lg"
            >
                <form @submit.prevent="uploadIfu">

                    <div v-for="language in languages" :key="language.id">
                    <b-form-group :label="'IFU ( ' + language.name + ' )'" >
                        <b-input-group>
                            <b-form-file
                                v-model="modelIfu.ifuFile[language.id]"
                                :state="Boolean(modelIfu.ifuFile[language.id])"
                                placeholder="Upload IFU file (*.pdf)..."
                                drop-placeholder="Drop file here..."
                            ></b-form-file>

                            <b-input-group-append>
                                <b-button
                                    v-if="isIntentoSoftware"
                                    :disabled="!modelIfu.ifuFile[language.id]"
                                    @click.prevent="
                                        uploadIfu(
                                            modelIfu.appVersionId,
                                            language.id
                                        )
                                    "
                                    size="sm"
                                    class="m-1"
                                    variant="primary"
                                >
                                    <b-icon-cloud-upload />
                                </b-button>

                                <b-button
                                    :disabled="
                                        !(isIntentoSoftware &&
                                        modelIfu.ifu[language.id] != null)
                                    "
                                    @click.prevent="getIfuFile(modelIfu.ifu[language.id])"
                                    size="sm"
                                    class="m-1"
                                    variant="primary"
                                >
                                    <b-icon-eye />
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                    </div>
                </form>
                <div class="form-group">
                    <div v-if="errorMessage" class="alert alert-danger" role="alert">
                        {{ errorMessage }}
                    </div>
                </div>
            </b-modal>
        </b-row>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "@/services/api.service";

export default {
    name: "SoftwareDashboard",
    data() {
        return {
            loading: false,
            languages: [ { id : 0, code : 'en', name : 'English'}, 
                         { id : 1, code : 'fr', name : 'French'}, 
                         { id : 2, code : 'de', name : 'German'},   
                         { id : 3, code : 'it', name : 'Italian'},
                         { id : 4, code : 'es', name : 'Spanish'}],
            model: {},
            modelApp: {},
            modelIfu: {
                // We cannot reference the length of languages here, value is hard-coded
                ifu : [], 
                ifuFile : [],
                appVersionId : 0
            },
            modelUploadIfu: {},
            ifuFile: null,
            firmwareFile: null,
            firmwareUpdates: {},
            tabletUpdates: {},
            appVersionIfus: {},
            errorMessage: ''
        };
    },
    async created() {
        this.refreshFirmwareUpdates();
        this.refreshTabletAppUpdates();
    },
    computed: {
        ...mapGetters("auth", ["isIntentoSoftware"]),
    },
    methods: {
        //---------------------
        // Firmware updates
        //---------------------
        async refreshFirmwareUpdates() {
            this.firmwareUpdates = await ApiService.getFirmwareUpdates();
        },
        async openNewFirmwareUpdateForm() {
            this.model = {}; // reset form
            this.$bvModal.show("modal-upload-firmware-update");
        },
        async uploadFirmwareUpdate() {
            try {
                await ApiService.addFirmwareUpdate(this.firmwareFile);
            } catch (error) {
                this.$bvToast.show("toast-upload-failed");
            }
            this.refreshFirmwareUpdates();
        },
        async saveFirmwareUpdateMetadata(event) {
            event.preventDefault()
            this.model.releaseNotes = [
                {
                    language: "en",
                    description: this.model.release_notes_en,
                },
                {
                    language: "fr",
                    description: this.model.release_notes_fr,
                },
                {
                    language: "de",
                    description: this.model.release_notes_de,
                },
                {
                    language: "it",
                    description: this.model.release_notes_it,
                },
                {
                    language: "es",
                    description: this.model.release_notes_es,
                },
            ];
            this.model.isMandatoryUpdate =
                this.model.isMandatoryUpdate == "True" ? true : false;

            await ApiService.updateFirmwareUpdateMetadata(this.model.id, this.model).then(
                            () => { 
                                this.model = {}; // reset form
                                // Hide the modal manually
                                this.$nextTick(() => {
                                    this.$bvModal.hide("modal-edit-firmware-update-metadata");
                                });
                            },
                            (error) => {
                                    this.errorMessage =
                                    (error.response && error.response.data)
                                    || error.message
                                    || error.toString();
                                });
            this.refreshFirmwareUpdates();
        },
        async populateEditFirmwareUpdateForm(id) {
            this.model = await ApiService.getFirmwareUpdateMetadata(id);
            this.model.release_notes_en = this.getReleaseNote(
                this.model.releaseNotes,
                "en"
            );
            this.model.release_notes_fr = this.getReleaseNote(
                this.model.releaseNotes,
                "fr"
            );
            this.model.release_notes_de = this.getReleaseNote(
                this.model.releaseNotes,
                "de"
            );
            this.model.release_notes_it = this.getReleaseNote(
                this.model.releaseNotes,
                "it"
            );
            this.model.release_notes_es = this.getReleaseNote(
                this.model.releaseNotes,
                "es"
            );
            this.model.isMandatoryUpdate = this.model.isMandatoryUpdate
                ? "True"
                : "False";
            this.$bvModal.show("modal-edit-firmware-update-metadata");
            this.errorMessage = '';
        },
        getReleaseNote(notes, language) {
            var note = notes.find(
                (noteFound) => noteFound.language.localeCompare(language) == 0
            );
            if (note != null) {
                return note.description;
            } else {
                return "";
            }
        },
        getFirmwareFile(id) {
            return ApiService.getFirmwareUpdateFile(id);
        },

        //---------------------
        // Tablet App updates
        //---------------------
        async refreshTabletAppUpdates() {
            this.tabletUpdates = await ApiService.getTabletUpdates();
        },
        async openTabletAppUpdateForm() {
            this.modelApp = {}; // reset form
            this.$bvModal.show("modal-edit-app-update-metadata");
            this.errorMessage = '';
        },
        async saveAppUpdateMetadata(event) {
            event.preventDefault();
            this.modelApp.isMandatoryUpdate =
                this.modelApp.isMandatoryUpdate == "True" ? true : false;
            if (this.modelApp.id) {
                await ApiService.updateTabletUpdateMetadata(this.modelApp.id, this.modelApp).then(
                            () => { 
                                this.modelApp = {}; // reset form
                                // Hide the modal manually
                                this.$nextTick(() => {
                                    this.$bvModal.hide("modal-edit-app-update-metadata");
                                });
                            },
                            (error) => {
                                    this.errorMessage =
                                    (error.response && error.response.data)
                                    || error.message
                                    || error.toString();
                                });
            } else {
                await ApiService.addTabletUpdateMetadata(this.modelApp).then(
                            () => { 
                                this.modelApp = {}; // reset form
                                // Hide the modal manually
                                this.$nextTick(() => {
                                    this.$bvModal.hide("modal-edit-app-update-metadata");
                                });
                            },
                            (error) => {
                                    this.errorMessage =
                                    (error.response && error.response.data)
                                    || error.message
                                    || error.toString();
                                });
            }
            this.refreshTabletAppUpdates();
        },
        async populateEditTabletUpdateForm(id) {
            this.modelApp = await ApiService.getTabletUpdateMetadata(id);
            this.modelApp.isMandatoryUpdate =
                this.modelApp.isMandatoryUpdate ? "True" : "False";
            this.$bvModal.show("modal-edit-app-update-metadata");
            this.errorMessage = '';
        },

        //---------------------
        // IFU
        //---------------------
        async refreshIfuInformation(appVersionId) {

              this.modelIfu = {
                ifu : [],
                ifuFile : [],
                appVersionId : appVersionId
            }

            this.appVersionIfus = await ApiService.getIfuInformationByAppVersionId(
                appVersionId
            );

            for (let language of this.languages) {
                  this.modelIfu.ifu.push(this.appVersionIfus.find(
                    (ifu) => ifu.language === language.code));
            }
        },
        async populateIfuForm(id) {
            await this.refreshIfuInformation(id);
            this.$bvModal.show("modal-edit-ifu-update");
        },
        async uploadIfu(appVersion, languageId) {
            try {
                await ApiService.uploadIfuFile(appVersion, 
                                                this.languages[languageId].code, 
                                                this.modelIfu.ifuFile[languageId]);
            } catch (error) {
                this.$bvToast.show("toast-upload-failed");
            }
            this.refreshIfuInformation(appVersion);
        },
        async getIfuFile(ifu) {
            return ApiService.getIfuFile(ifu.fileId, ifu.filename);
        },
    },
};
</script>

<style scoped>
</style>